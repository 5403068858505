<template>
  <v-container class="pa-0 justify-center text-center" fluid fill-height>
    <v-row class="text-center fill-height">
      <v-col cols="12" class="mt-4">
        <v-img
          alt="PARKING.COM"
          class="my-3"
          height="42"
          contain
          src="https://parking.com/img/unity/img_logo_mobile_drk@2x.png"
        />
      </v-col>
      <v-col>
        <p class="text-h5 black--text">Sorry, the booking ID is invalid</p>
        <p class="text-h6 black--text">
          Try clicking on the booking link again
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InvalidBooking",
  data: () => ({}),
  methods: {},
};
</script>
<style lang="less">
</style>
